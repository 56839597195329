import React from 'react';
import styled from 'styled-components';
import { Shuffler } from 'src/components/atoms/Shuffler';
import { useTeamData } from 'src/hooks/use-team-data';
import { FixedImg } from 'src/embeds/image/image-dato';
const Grid = styled.div`
  display: grid;
  column-gap: 50px;
  row-gap: 50px;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
`;
const Cell = styled.div`
  > * {
    margin: 0;
    padding: 0;
  }
  > * + * {
    margin-top: 20px;
  }
`;
export function TeamList() {
  const team = useTeamData();
  return (
    <Grid>
      <Shuffler>
        {team.map(({ image, name, role }) => (
          <Cell key={name}>
            {image ? (
              <FixedImg
                data={image}
                width={160}
                height={160}
                style={{
                  margin: '0 auto',
                  borderRadius: '50vmin',
                  zIndex: 0,
                }}
                pictureStyle={{
                  zIndex: -1,
                }}
              />
            ) : (
              <div
                style={{
                  width: 160,
                  height: 160,
                  backgroundColor: '#ddd',
                  margin: '0 auto',
                  borderRadius: '50vmin',
                }}
              />
            )}
            <h3>{name}</h3>
            <p
              style={{
                marginTop: 10,
              }}
            >
              {role}
            </p>
          </Cell>
        ))}
      </Shuffler>
    </Grid>
  );
}
