import React from 'react';

import { Section, SectionHeader } from 'src/components/molecules/Section';
import { Grid } from 'src/components/molecules/Grid';
import { TeamList } from 'src/components/organisms/TeamList';

export default function Drawer({ heading }) {
  return (
    <Section centered>
      <SectionHeader>
        <h2>{heading}</h2>
      </SectionHeader>
      <TeamList />
    </Section>
  );
}
